import React, {Component} from 'react';
import lottie from 'lottie-web';

import WaterfallFlow from '../components/WaterfallFlow';
import WindowSizeListener from '../components/WindowSizeListener';

import Articles from './Articles';
import ThreeDmodel from './ThreeDmodel';
import Portfolioinfo from './Portfolioinfo';

import './Portfolio.css';

let _lottieAnimate = null;
let _WindowSizeListener = null;



class Portfolio extends Component{
	constructor(...args){
		super(...args);

		this.state = {
			Portfolioshow: false,
			show: false,
			id: '',
			artDatas: { //文章数据
				id: '',
				title: '',
				content: {},
				show: false
			},
			datas: { //3d数据
				id: '',
				title: '',
				canvas: {
					width: 0,
					height: 0
				},
				promptShow: true,
				show: false
			},
			infoDatas: {}, //综合数据（用于存储更新 <Portfolioinfo/> ）
			nav: {
				cur: 0,
				list: [
					{id: 0, title: '全部', type: 'all', 	  active: true},
					{id: 1, title: 'UI',  type: 'ui', 	  active: false},
					// {id: 2, title: '模型', type: '3d', 	  active: false},
					{id: 3, title: '手绘', type: 'painted', active: false}
				]
			},
			pageDatas: []
		};
	}

	async componentDidMount(){
		// this.init();
	}

	componentWillReceiveProps(nextProps){
		let lastShow = this.state.Portfolioshow,
			show 	 = nextProps.show;

		if (show !== lastShow) {
			this.updateInfo(show);
		}
	}

	updateInfo(Portfolioshow){
		this.setState({
			Portfolioshow
		}, () => {
			this.init();
		});
	}

	init(){

		let _this = this;

		this.setView(function(state){
			if (state) {
				_this.loadingScreen('clear');
				_this.loadingScreen('init');
				_this.fnSwitchPage('all');
			}else{

			}
		});		
	}

	setWindowSizeListener(){
		_WindowSizeListener = new WindowSizeListener(function(datas){
			console.log(datas);
		});

		_WindowSizeListener.init();
	}

	setView(callback){
		let obj     = this.refs.Portfolio_viewBox,
			// w  		= document.documentElement.clientWidth,
			h  		= document.documentElement.clientHeight,
			obj_box = this.refs.Portfolio_viewBox_inner,
			box_top = 0.95 * h > 720 ? (h - 720) / 2 : (0.05 * h ) / 2;

		// obj.style.width  = w + 'px';
		obj.style.height = h + 'px';

		obj_box.style.marginTop = box_top + 'px';

		// let obj_list = this.refs.Portfolio_WaterfallFlow,
		// 	list_h 	 = h - 72 - 110 - 36 - 60;

		// 	obj_list.style.height = list_h + 'px';

		callback(true);
	}

	async getData(type, callback){
		let _this = this,
			url   = _this.getUrl(type);

		try{
			await fetch(url)
					.then(function(res){

						return res.json();

					}).then(function(datas){

						callback(true, datas);

					}).catch(function(err){

						_this._textLog( 'error', err );

						callback(false, null);

					});
		}
		catch(err){
			_this._textLog( 'error', err );
		}
	}

	getUrl(type){
		let url = '';

		switch(type){
			case 'all':
				url = './resource/datas/all.json';
				break;

			case 'ui':
				url = './resource/datas/ui.json';
				break;

			case '3d':
				url = './resource/datas/3d.json';
				break;

			case 'painted':
				url = './resource/datas/painted.json';
				break;

			default:
				console.log('default');
		}

		return url;
	}

	getHtmlLoading(){
		return (
			<div ref="Portfolio_loadingScreen" className="normal_animate Portfolio_loadingScreen">
				<div className="Portfolio_loadingScreen_inner">
					<div ref="Portfolio_loadingScreen_pic" className="Portfolio_loadingScreen_pic"></div>
					<div className="font-serif loading_text">Loading...</div>
				</div>
			</div>
		);
	}

	getHtmlNav(type){
		let domtype   = type,
			className = 'normal_animate', //Portfolio_normalNav || Portfolio_fixedNav
			navData   = this.state.nav.list;

		try{
			switch(domtype){
				case 'nav':
					className = className + ' Portfolio_normalNav';
					break;

				case 'scrollfixed':
					className = className + ' Portfolio_fixedNav';
					break;

				default:
					return null;
			}
		}catch(error){
			console.error(error);
		}

		let html = navData.map( (datas, key) => {

			let active   = datas.active ? ' nav_cur' : '',
				curClass = 'normal_animate animate_hover' + active;

			return <li 
					key={ key } 
					id={ datas.id } 
					className={ curClass }
					onClick={this.fnNavClick.bind(this, datas.id, datas.type, domtype)}>
						<p className="normal_animate font-serif">{ datas.title }</p>
					</li>

		});

		return (
			<div className={ className }>
				<ul className="display_inlineFlex Portfolio_headNav">{ html }</ul>
			</div>
		);
	}

	getHtmlList(){
		let pageDatas 	 = this.state.pageDatas,
			html 	  	 = pageDatas.map( (datas, key) => {

			return <li 
					key={ key } 
					id={ datas.id } 
					src={ datas.cover }
					snapshot={ datas.snapshot }
					className="normal_animate animate_hover display_inlineBlock" 
					onClick={this.fnListClick.bind(this, datas.id, datas.type)}>
						<p className="normal_animate animate_hover">{ datas.title }</p>
					</li>

		} );

		return (
			<ul className="Portfolio_list" id="Portfolio_list">{ html }</ul>
		);
	}

	getHtmlModelinfo(datas){
		return <h3 className="delay_animate_600">{this.state.datas.title}</h3>
	}

	fnNavClick(id, type, domtype){
		let _this = this,
			nav   = this.state.nav,
			cur   = nav.cur,
			list  = nav.list;

			id    = parseInt(id);

		for (let i = 0; i < list.length; i++) {
			if (list[i].id === id) {

				cur 		   = id;
				list[i].active = true;

			}
			else{
				list[i].active = false;
			}
		}

		nav.list = list;
		nav.cur  = cur;

		this.setState({
			nav
		}, () => {
			_this.fnScrollInit(domtype);
			_this.fnSwitchPage(type);
		});
	}

	fnScrollInit(domtype){
		let obj = document.getElementById('Main_box');
			// top = document.getElementById('Portfolio_WaterfallFlow').offsetTop;

		obj.scrollTop = domtype === 'nav' ? 0 : 210;
	}

	fnSwitchPage(type){
		let _this 	  = this,
			pageDatas = [];

		this.setState({
			pageDatas
		});

		this.getData(type, function(state, datas){

			if (state) {
				pageDatas = datas.datas;

				_this.setState({
					pageDatas
				}, () => {
					_this.loadingScreen('show');
					_this.setWaterfallFlow();
				});
			}else{
				_this._textLog( 'error', datas.err );
			}

		});
	}

	setWaterfallFlow(){
		let _this = this;
		let datas = {
						container: 'Portfolio_list',
						list: 'li',
						scroll: 'Portfolio_WaterfallFlow',
						spacing: {
									row: 14,
									column: 14,
									unit: 'px'
								},
						delay: 90
					}

		new WaterfallFlow(datas, function(status){
			_this.loadingScreen('close');
		});
	}

	fnListClick(id, type){
		let arry  = this.fillDatas(id),
			show  = this.fnSwtichShow();

		if (type === 'articles') { //文章
			let artDatas  = {
								id: arry.id,
								title: arry.title,
								content: arry.content,
								show: show
							},
				infoDatas = {
								show: show,
								datas: arry
							};

			this.setState({
				show,
				id,
				artDatas,
				infoDatas
			});
		}
		else if (type === '3d') {
			let size     = this.getSize(),
				datas    = {
								id: arry.id,
								title: arry.title,
								canvas: {
									width: size.width,
									height: size.height
								},
								promptShow: this.state.datas.promptShow,
								show: show
							},
				infoDatas = {
								show: show,
								datas: arry
							};

			this.setState({
				show,
				id,
				datas,
				infoDatas
			});
		}

		this.fnCheckMainNav(show);
	}

	fnCloseBox(){
		this.fnClearData();
	}

	fillDatas(id){
		let pageDatas = this.state.pageDatas,
			datas	  = {};

		for (let i = 0; i < pageDatas.length; i++) {

			if (pageDatas[i].id === id) {
				datas = pageDatas[i];

				break;
			}
		}

		return datas;
	}

	getSize(){
		let obj = this.refs.ModelWindowBox,
			width = obj.offsetWidth,
			height = obj.offsetHeight,
			canvas = {
						width: width,
						height: height
					};

		return canvas;
	}

	fnSwtichShow(){
		let show  = this.state.show ? false : true;

		return show;
	}

	fnClearData(){
		let show  	  = false,
			id 	  	  = '',
			artDatas  = {
							id: '',
							title: '',
							content: {},
							show: false
						},
			datas 	  = {
							id: '',
							title: '',
							canvas: {
								width: 0,
								height: 0
							},
							promptShow: true,
							show: false
						},
			infoDatas = {
							show: false,
							datas: {}
						};

		this.fnCheckMainNav(show);
		this.setState({
			show,
			id,
			artDatas,
			datas,
			infoDatas
		});
	}

	fnCheckMainNav(show){
		let obj = document.getElementById('Main_headNav');

		if (show) {
			obj.style.zIndex = '2';
		}else{
			obj.style.zIndex = '3';
		}
	}

	loadingScreen(type){
		let obj_box = this.refs.Portfolio_loadingScreen,
			obj 	= this.refs.Portfolio_loadingScreen_pic,
			src 	= './resource/images/contentLoadingScreen.json';

		try{
			switch(type){
				case 'init':
					_lottieAnimate = lottie.loadAnimation({
						container: obj,
						renderer: 'svg',
						loop: true,
						path: src,
					});
					break;

				case 'show':
					obj_box.className = 'normal_animate Portfolio_loadingScreen Portfolio_loadingShow';
					break;

				case 'close':
					obj_box.className = 'normal_animate Portfolio_loadingScreen Portfolio_loadingClose';
					break;

				case 'clear':
					_lottieAnimate.destroy();
					break;

				default:
					console.log('default');
			}
		}
		catch(err){
			this._textLog( 'error', err );
		}
	}

	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			<div ref="Portfolio_window" className="Portfolio_window">

				{this.getHtmlNav('scrollfixed')}

				{this.getHtmlNav('nav')}
				
				<div ref="Portfolio_WaterfallFlow" className="Portfolio_WaterfallFlow" id="Portfolio_WaterfallFlow">
					{this.getHtmlLoading()}
					{this.getHtmlList()}
				</div>

				<div ref="Portfolio_viewBox" className={`Portfolio_viewBox normal_animate ${this.state.show ? 'Portfolio_viewBox_show' : 'Portfolio_viewBox_hide'}`}>
					<div className="Portfolio_viewBox_Mask normal_animate" onClick={this.fnCloseBox.bind(this)}></div>
					<div ref="Portfolio_viewBox_inner" className="normal_animate Portfolio_viewBox_inner">

						<div className="normal_animate animate_hover Portfolio_viewBox_btn_close" onClick={this.fnCloseBox.bind(this)}></div>
						
						<div ref="ModelWindowBox" className="ModelWindowBox normal_animate">
							<Articles
								datas={this.state.artDatas}
							/>

							<ThreeDmodel
								datas={this.state.datas}
							 />
						</div>
						<div className="Modelinfo delay_animate_360">
							<Portfolioinfo 
								datas={this.state.infoDatas}
							/>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Portfolio;