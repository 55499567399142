import React, {Component} from 'react';
import lottie from 'lottie-web';

import MobileArticles from './MobileArticles';
import MobileThreeDmodel from './MobileThreeDmodel';
// import MobilePortfolioinfo from './MobilePortfolioinfo';

import './MobilePortfolio.css';

let _lottieAnimate = null;



class MobilePortfolio extends Component{
	constructor(...args){
		super(...args);

		this.state = {
			Portfolioshow: false,
			show: false,
			id: '',
			artDatas: { //文章数据
				id: '',
				title: '',
				content: {},
				show: false
			},
			datas: { //3d数据
				id: '',
				title: '',
				canvas: {
					width: 0,
					height: 0
				},
				promptShow: true,
				show: false
			},
			infoDatas: {}, //综合数据（用于存储更新 <MobilePortfolioinfo/> ）
			nav: {
				cur: 0,
				list: [
					{id: 0, title: '全部', 	 type: 'all', 	  active: true},
					{id: 1, title: 'UI',  type: 'ui', 	  active: false},
					// {id: 2, title: '模型', type: '3d', 	  active: false},
					{id: 3, title: '手绘', type: 'painted', active: false}
				]
			},
			pageDatas: []
		};
	}

	async componentDidMount(){
		// this.init();
	}

	componentWillReceiveProps(nextProps){
		let lastShow = this.state.Portfolioshow,
			show 	 = nextProps.show;

		if (show !== lastShow) {
			this.updateInfo(show);
		}

		this.fnSetScrollTop('init');
	}

	updateInfo(Portfolioshow){
		this.setState({
			Portfolioshow
		}, () => {
			this.init();
		});
	}

	init(){
		this.loadingScreen('clear');
		this.loadingScreen('init');
		this.fnSwitchPage('all');
	}

	async getData(type, callback){
		let _this = this,
			url   = _this.getUrl(type);

		try{
			await fetch(url)
					.then(function(res){

						return res.json();

					}).then(function(datas){

						callback(true, datas);

					}).catch(function(err){

						_this._textLog( 'error', err );

						callback(false, null);

					});
		}
		catch(err){
			_this._textLog( 'error', err );
		}
	}

	getUrl(type){
		let url = '';

		switch(type){
			case 'all':
				url = './resource/datas/all.json';
				break;

			case 'ui':
				url = './resource/datas/ui.json';
				break;

			case '3d':
				url = './resource/datas/3d.json';
				break;

			case 'painted':
				url = './resource/datas/painted.json';
				break;

			default:
				console.log('default');
		}

		return url;
	}

	getHtmlLoading(){
		return (
			<div ref="MobilePortfolio_loadingScreen" className="normal_animate MobilePortfolio_loadingScreen">
				<div className="MobilePortfolio_loadingScreen_inner">
					<div ref="MobilePortfolio_loadingScreen_pic" className="MobilePortfolio_loadingScreen_pic"></div>
					<div className="font-serif loading_text">Loading...</div>
				</div>
			</div>
		);
	}

	getHtmlNav(){
		let navData = this.state.nav.list;

		let html = navData.map( (datas, key) => {

			let active  = datas.active ? ' nav_cur' : '',
				curClass = 'normal_animate animate_hover' + active;

			return <li 
					key={ key } 
					id={ datas.id } 
					className={ curClass }
					onClick={this.fnNavClick.bind(this, datas.id, datas.type)}>
						<p className="normal_animate font-serif">{ datas.title }</p>
					</li>

		} );

		return (
			<ul className="display_inlineFlex MobilePortfolio_headNav">{ html }</ul>
		);
	}

	getHtmlList(){
		let pageDatas 	 = this.state.pageDatas,
			html_loading = this.getHtmlLoading(),
			html 	  	 = pageDatas.map( (datas, key) => {

			return <li 
					key={ key } 
					id={ datas.id } 
					src={ datas.cover }
					snapshot={ datas.snapshot }
					className="normal_animate animate_hover" 
					style={{backgroundImage: `url(` + datas.snapshot + `)`}} 
					onClick={this.fnListClick.bind(this, datas.id, datas.type)}>
						<p className="normal_animate animate_hover">{ datas.title }</p>
					</li>

		} );

		return (
			<ul className="display_inlineFlex MobilePortfolio_list" ref="MobilePortfolio_list">{ html_loading } { html }</ul>
		);
	}

	getHtmlModelinfo(datas){
		return <h3 className="delay_animate_600">{this.state.datas.title}</h3>
	}

	fnNavClick(id, type){
		let _this = this,
			nav   = this.state.nav,
			cur   = nav.cur,
			list  = nav.list;

			id    = parseInt(id);

		for (let i = 0; i < list.length; i++) {
			if (list[i].id === id) {

				cur 		   = id;
				list[i].active = true;

			}
			else{
				list[i].active = false;
			}
		}

		nav.list = list;
		nav.cur  = cur;

		this.setState({
			nav
		}, () => {
			_this.fnSwitchPage(type);
		});
	}

	fnSwitchPage(type){
		let _this 	  = this,
			pageDatas = [],
			timer 	  = null;

		this.fnSetScrollTop('init');

		this.setState({
			pageDatas
		});

		this.getData(type, function(state, datas){

			_this.loadingScreen('show');

			if (state) {
				pageDatas = datas.datas;

				timer = setTimeout(function(){
					clearTimeout(timer);

					_this.loadingScreen('close');

					_this.setState({
						pageDatas
					});
				}, 1000);

			}else{
				_this._textLog( 'error', datas.err );
			}

		});
	}

	fnSetScrollTop(type){
		let obj = this.refs.MobilePortfolio_list;

		try{
			switch(type){
				case 'init':
					obj.scrollTop = 0;
					break;

				default:
					console.log('default');
			}
		}
		catch(err){
			this._textLog( 'error', err );
		}
	}

	fnListClick(id, type){
		let arry  = this.fillDatas(id),
			show  = this.fnSwtichShow();

		if (type === 'articles') { //文章
			let artDatas  = {
								id: arry.id,
								title: arry.title,
								content: arry.content,
								show: show
							},
				infoDatas = {
								show: show,
								datas: arry
							};

			this.setState({
				show,
				id,
				artDatas,
				infoDatas
			});
		}
		else if (type === '3d') {
			let size     = this.getSize(),
				datas    = {
								id: arry.id,
								title: arry.title,
								canvas: {
									width: size.width,
									height: size.height
								},
								promptShow: this.state.datas.promptShow,
								show: show
							},
				infoDatas = {
								show: show,
								datas: arry
							};

			this.setState({
				show,
				id,
				datas,
				infoDatas
			});
		}

		this.fnCheckMainNav(show);
	}

	fnCloseBox(){
		this.fnClearData();
	}

	fillDatas(id){
		let pageDatas = this.state.pageDatas,
			datas	  = {};

		for (let i = 0; i < pageDatas.length; i++) {

			if (pageDatas[i].id === id) {
				datas = pageDatas[i];

				break;
			}
		}

		return datas;
	}

	getSize(){
		let obj    = this.refs.ModelWindowBox,
			width  = obj.offsetWidth,
			height = obj.offsetHeight,
			canvas = {
						width: width,
						height: height
					};

		return canvas;
	}

	fnSwtichShow(){
		let show  = this.state.show ? false : true;

		return show;
	}

	fnClearData(){
		let show  	  = false,
			id 	  	  = '',
			artDatas  = {
							id: '',
							title: '',
							content: {},
							show: false
						},
			datas 	  = {
							id: '',
							title: '',
							canvas: {
								width: 0,
								height: 0
							},
							promptShow: true,
							show: false
						},
			infoDatas = {
							show: false,
							datas: {}
						};

		this.fnCheckMainNav(show);
		this.setState({
			show,
			id,
			artDatas,
			datas,
			infoDatas
		});
	}

	fnCheckMainNav(show){
		let obj 	 = document.getElementById('MobileMain_headNav'),
			obj_logo = document.getElementById('MobileMain_logo');

		if (show) {
			obj.style.zIndex = '2';
			obj_logo.style.zIndex = '2';
		}else{
			obj.style.zIndex = '3';
			obj_logo.style.zIndex = '3';
		}
	}

	loadingScreen(type){
		let obj_box = this.refs.MobilePortfolio_loadingScreen,
			obj 	= this.refs.MobilePortfolio_loadingScreen_pic,
			src 	= './resource/images/contentLoadingScreen.json';

		try{
			switch(type){
				case 'init':
					_lottieAnimate = lottie.loadAnimation({
						container: obj,
						renderer: 'svg',
						loop: true,
						path: src,
					});
					break;

				case 'show':
					obj_box.className = 'normal_animate MobilePortfolio_loadingScreen MobilePortfolio_loadingShow';
					break;

				case 'close':
					obj_box.className = 'normal_animate MobilePortfolio_loadingScreen MobilePortfolio_loadingClose';
					break;

				case 'clear':
					_lottieAnimate.destroy();
					break;

				default:
					console.log('default');
			}
		}
		catch(err){
			this._textLog( 'error', err );
		}
	}

	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			<div ref="MobilePortfolio_window" className="MobilePortfolio_window">

				{this.getHtmlNav()}
				
				{this.getHtmlList()}

				<div ref="MobilePortfolio_viewBox" className={`MobilePortfolio_viewBox normal_animate ${this.state.show ? 'MobilePortfolio_viewBox_show' : 'MobilePortfolio_viewBox_hide'}`}>
					<div className="MobilePortfolio_viewBox_Mask normal_animate" onClick={this.fnCloseBox.bind(this)}></div>
					<div ref="MobilePortfolio_viewBox_inner" className="normal_animate MobilePortfolio_viewBox_inner">

						<div className="normal_animate animate_hover MobilePortfolio_viewBox_btn_close" onClick={this.fnCloseBox.bind(this)}></div>
						
						<div ref="ModelWindowBox" className="ModelWindowBox normal_animate">
							<MobileArticles
								datas={this.state.artDatas}
								infoDatas={this.state.infoDatas}
							/>

							<MobileThreeDmodel
								datas={this.state.datas}
								infoDatas={this.state.infoDatas}
							 />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MobilePortfolio;