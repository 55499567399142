/**
 * @auhor 		by xiamu
 * @Date 		2022/07/04
 * @version 	v1.0.0
 * @content 
 * 				for Parallax check gyro from mobile
 * 				link -->> " import Parallax from 'parallax-js' " <<--
 * 		
 * @param 		datas 				{console: true/false} 				get status true or false mean Gyro On or Off
 * @return 		Function 			callback(true/false)				return function( status ) status about true or false
 */

const GyroCheck = function(datas, callback){
	let _this 	  = this,
		_CallBack = callback;
 
	this.init = function(){

		if (window.DeviceMotionEvent){

			if (datas.console) {
				window.addEventListener("devicemotion", _this.getGravity, false);
				window.addEventListener("deviceorientation", _this.getGravity, false);
			};

			_this.getPermission();
		}

	}

	this.getPermission = function(){

		if (
			typeof DeviceMotionEvent !== "undefined" &&
			typeof DeviceMotionEvent.requestPermission === "function"
		){

			DeviceMotionEvent.requestPermission().then(function (state) { //ios > 13

				if (state === 'granted') {

					if (datas.console) {
						window.addEventListener("devicemotion", _this.getGravity, false);
						window.addEventListener("deviceorientation", _this.getGravity, false);
					};

					_this.fnDone(true);
				}
				else if (state === 'denied'){

					_this.fnDone(false);
				}
				else if (state === 'prompt'){
					_this.fnDone(false);
				}

			}).catch(function (err) {
				console.error(err);
				_this.fnDone(false);
			});
		}
	}

	this.getGravity = function(e){
		console.log("x:",e.alpha); // X
		console.log("y:",e.beta); // Y
		console.log("z:",e.gamma); // Z
	}

	this.fnDone = function(state){
		_CallBack(state);
	}

	this.init();
}

export default GyroCheck;