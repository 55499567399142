import React, {Component} from 'react';
import DeviceCheck from './components/DeviceCheck';
import Main from './pages/Main';
// import LearnMain from './LearnPage/threejs基础11-12';
import MobileMain from './mobilePage/MobileMain';
// import './App.css';

class App extends Component{
  constructor(...args){
    super(...args);

    this.state = {};
  }

  componentDidMount(){
    this.fnDeviceCheck();
    this.fnSwitchView();
  }

  fnDeviceCheck(){
    let datas       = new DeviceCheck(),
        deviceDatas = {
          device: datas.device,
          system: datas.system,
          browser: datas.browser
        };

    return deviceDatas.device;
  }

  fnSwitchView(){
    if (this.fnDeviceCheck() === 'phone') {
      let obj = document.getElementById('root');

      obj.className += 'Mobileroot';

      this.fnAdaptiveSize(720, 100);
    }
    else{

    }
  }

  fnAdaptiveSize(designWidth, rem2px){
    let d = window.document.createElement('div');   //创建模型

    d.setAttribute('id','adaptive_size_d');
    d.style.width = '1rem';
    d.style.display = 'none';

    let head = window.document.getElementsByTagName('head')[0];

    head.appendChild(d);

    let defaultFontSize = parseFloat(window.getComputedStyle(d,null).getPropertyValue('width'));

    document.getElementById('adaptive_size_d').style.display = 'none';
    document.documentElement.style.fontSize = window.innerWidth / designWidth * rem2px / defaultFontSize * 100 + '%';

    let st = document.createElement('style');
    let portrait = "@media screen and (min-width:" + window.innerWidth + "px) {html{font-size:" + ((window.innerWidth / (designWidth / rem2px) / defaultFontSize) * 100) + "%}}";
    let landscape = "@media screen and (min-width:" + window.innerHeight + "px) {html{font-size:" + ((window.innerHeight / (designWidth / rem2px) / defaultFontSize) * 100) + "%}}";
    
    st.innerHTML = portrait + landscape;
    head.appendChild(st);

    return defaultFontSize
  }

  render(){
    return(
      <div className="App">
        { this.fnDeviceCheck() === 'phone' ? <MobileMain /> : <Main /> }
        {/*{ <LearnMain /> }*/}
      </div>
    );
  }
}

export default App;
