// 2022/06/24
// by xiamu
// datas{
// 		container 				---obj name
// 		list					---obj name
// 		scroll					---obj name
// 		spacing {
// 					row			---num
// 					column		---num
//					unit		---num
// 				}
// 		delay 					---num
// 	}

const _timestamp_WFlow = {
							timer: null,
							new: 0
						};

const WaterfallFlow = function(datas, callback){
	let _this = this;

	let _CallBack = callback;

	let container = document.getElementById(datas.container),
		scroll 	  = document.getElementById(datas.scroll),
		list 	  = container.getElementsByTagName(datas.list);

	let arry   = [],
		max_w  = 0,
		column = 0,
		row	   = 0,
		_w     = 0,
		_i 	   = 0,
		_x 	   = 0,
		num    = 0;

	let _time_x = 0,
		_delay  = datas.delay;

	let _LazyLoadDatas = [];
 
	this.init = function(){
		clearTimeout(_timestamp_WFlow.timer);
		_timestamp_WFlow.new = new Date().getTime();

		arry    = [];
		max_w   = container.clientWidth;
		column  = datas.spacing.column;
		row     = datas.spacing.row;
		_w      = list[0].clientWidth;
		_i	    = 0;
		_x	    = 0;
		num     = parseInt(max_w / _w);
		_time_x = 0;
		_LazyLoadDatas = [];

		this.fnSetScrollTop('init');
		this.fnGetDatas();
	}

	this.fnSetScrollTop = function(type){
		try{
			switch(type){
				case 'init':
					scroll.scrollTop = 0;
					break;

				default:
					console.log('default');
			}
		}
		catch(err){
			console.error(err);
		}
	}

	this.fnGetDatas = function(){
		let src = '',
			lazyArry = {
				id: _i,
				timestamp: _timestamp_WFlow.new
			},
			img = {
					id: _i,
					ratio: 0,
					w: 0,
					h: 0,
					x: 0,
					y: 0
				};

		if (_i < list.length) {
			(async () => {

				if (this.fnSetSnapshot(list[_i].getAttribute('src'))) {
					src = list[_i].getAttribute('snapshot');

					// await this.fnMaxSizeLazyLoad(_i, _timestamp_WFlow.new).then(datas => {
					// 	_this.upDateLazyLoad(datas);
					// });

					_LazyLoadDatas.push(lazyArry);
				}else{
					src = list[_i].getAttribute('src');
				}

				list[_i].style.backgroundImage = 'url(' + src + ')';

				img = await this.fnGetImageSize(_i, src);

				arry.push(img);

				_i++;

				this.fnGetDatas();
				this.fnSetWinBox();
			})();
		}
		else{

			if(_LazyLoadDatas.length > 0) {this.fnGetLazyDatas()};

			this.fnSetPosition();
			this.fnSetFadeIn();
		}
	}

	this.fnGetImageSize = function(id, url){

		return new Promise(function (resolve, reject) {

			let image = new Image();

			image.onload = function () {

				let ratio = Math.floor( image.width / image.height * 100 ) / 100;

				resolve({
					id: id,
					ratio: ratio,
					w: _w,
					h: _w / ratio,
					x: 0,
					y: 0
				});
			};

			image.onerror = function () {
				reject(new Error('error'));
			};

			image.src = url;

		});
	}

	this.fnGetLazyDatas = function(){
		let id = 0,
			timestamp = 0;

		if (_x < _LazyLoadDatas.length) {

			id = _LazyLoadDatas[_x].id;
			timestamp = _LazyLoadDatas[_x].timestamp;

			if (_timestamp_WFlow.new === timestamp) {
				(async () => {

					await this.fnMaxSizeLazyLoad(id, timestamp).then(datas => {
						_this.upDateLazyLoad(datas);
					});

					_x++;

					this.fnGetLazyDatas();

				})();
			}
		}
	}

	this.fnMaxSizeLazyLoad = function(id, timestamp){
		let url = list[id].getAttribute('src');

		return new Promise(function (resolve, reject) {

			let image = new Image();

			image.onload = function () {
				resolve({
					timestamp: timestamp,
					id: id,
					src: url,
					type: 'MaxSizeLazyLoad',
					status: 'success'
				});
			};

			image.onerror = function () {
				reject(new Error('error'));
			};

			image.src = url;

		});
	}

	this.upDateLazyLoad = function(datas){
		let t = setTimeout(function(){
			if (_timestamp_WFlow.new === datas.timestamp) {
					list[datas.id].style.backgroundImage = 'url(' + datas.src + ')';
			}

			clearTimeout(t);
		}, 1000);
	}

	this.fnSetSnapshot = function(url){
		let match 	 = url.match(/\.(\w+)$/);

		match = match ? match[1] : null;

		return match === 'gif' ? true : false;
	}

	this.fnSetPosition = function(){
		let h = 0,
			x = 0,
			y = 0;

		for (let i = 0; i < list.length; i++) {

			h = arry[i].h;
			x = i > 0 && i % num > 0 ? arry[i-1].x + arry[i-1].w + column : column / 2;
			x = i % num === 0 ? 0 : x;
			y = i >= num ? arry[i-num].y + arry[i-num].h + row : 0;

			arry[i].x = x;
			arry[i].y = y;

			list[i].style.opacity   = 0;
			list[i].style.height 	= h + 'px';
			list[i].style.transform = 'translate(' + x + 'px, ' + y + 'px)';
		}
	}

	this.fnSetFadeIn = function(){
		_timestamp_WFlow.timer = _time_x < list.length ? setTimeout(function(){

			clearTimeout(_timestamp_WFlow.timer);

			list[_time_x].style.opacity = 1;

			_time_x++;

			_this.fnSetFadeIn();

		}, _delay) : _this.fnDone();
	}

	this.fnSetWinBox = function(){
		let box_h = 0;

		for (let i = 0; i < arry.length; i++) {
			box_h = box_h < arry[i].y + arry[i].h ? arry[i].y + arry[i].h : box_h;
		}

		container.style.height = box_h + 'px';
	}

	this.fnDone = function(){
		_CallBack(true);
	}

	this.init();
}
// transform: translate(528px, 0px);
export default WaterfallFlow;