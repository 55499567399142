import React, {Component} from 'react';

import { 
	Scene, 
	PerspectiveCamera, 
	WebGLRenderer, 
	PlaneGeometry, 
	MeshBasicMaterial,
	MeshLambertMaterial, 
	// MeshStandardMaterial,
	MeshToonMaterial,
	TextureLoader,
	NearestFilter,
	Mesh, 
	AmbientLight,
	DirectionalLight, 
	PointLight,
	AxesHelper, 
	sRGBEncoding,
	Color,
	Clock,
	AnimationMixer
} from 'three';

import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect.js';



import './ThreeDmodel.css';

const DataJson = [
		// {id: 0, type: 'gltf', name: '名片', mtl: './resource/model/0/view.mtl', obj: './resource/model/0/view.obj', gltf: './resource/model/0/view1.gltf'},
		// {id: 1, type: 'gltf', name: '小卖部', mtl: './resource/model/1/view.mtl', obj: './resource/model/1/view.obj', gltf: './resource/model/1/view.gltf'},
		// {id: 2, type: 'fbx', name: 'demo', fbx: './resource/model/demo/Xbot.glb'}
		{id: 0, type: 'gltf', name: 'demo',   mtl: '', obj: '', gltf: './resource/model/0/demo/model.gltf', material: './resource/model/0/demo/material.json',  shadertype: 'celluloid'},
		{id: 1, type: 'gltf', name: 'bottle', mtl: '', obj: '', gltf: './resource/model/1/demo/model.gltf', material: './resource/model/1/demo//material.json', shadertype: 'celluloid'},
		{id: 2, type: 'gltf', name: 'test',   mtl: '', obj: '', gltf: './resource/model/2/demo/model.gltf', material: './resource/model/2/demo//material.json', shadertype: 'celluloid'},
		{id: 100, type: 'gltf', name: 'test001',   mtl: '', obj: '', gltf: './resource/model/test/demo/model.gltf', material: './resource/model/test/demo//material.json', shadertype: 'normal'},
		{id: 101, type: 'gltf', name: 'test001',   mtl: '', obj: '', gltf: './resource/model/test/anim_demo/model.gltf', material: './resource/model/test/anim_demo//material.json', shadertype: 'normal'}
	];

let objScene    		 = null;
let objRenderer 		 = null;
let objControls 		 = null;
const objPlaneGeometry   = new PlaneGeometry(60, 60, 60, 1);

const objMTLLoader 		 = new MTLLoader();
const objOBJLoader 		 = new OBJLoader();
const objFBXLoader 		 = new FBXLoader();
const objGLTFLoader 	 = new GLTFLoader();

let objCamera   		 = null;

let objAmbientLight 	 = null;
let objPointLight		 = null;
let objSpotLight 		 = null;
let objOutLine 			 = null;

const objClock 			 = new Clock();
let objMixer			 = null;

const objAxisHelper 	 = new AxesHelper(300);

let objMaterial     	 = null;
let objPlane 			 = null;

// const objThreeTone 		 = new TextureLoader().load('./resource/assets/gradientMaps/threeTone.jpg');
// const objFiveTone	 	 = new TextureLoader().load('./resource/assets/gradientMaps/fiveTone.jpg');
let objTone 			 = null;

let animationId 		 = '';
let arryActions 			 = [];

class ThreeDmodel extends Component{
	constructor(...args){
		super(...args);

		this.state = {
			environment: 'Venice Sunset',
			axesHelper: false,
			canvasInfo: {
				width: 0,
				height: 0
			},
			modelInfo: {
				id: '',
				name: '',
				mtl: '',
				obj: '',
				fbx: '',
				other: {
					stroke: {
						'visible': true,
						'width': 0.0015,
						'color': "000000"
					},
					tone: {
						num: 3,
						url: './resource/assets/gradientMaps/threeTone.jpg'
					}
				}
			},
			modelDatas: {},
			camera: {
				position: {
					x: 0,
					y: 600,
					z: 2000
				},
				lookAt: {
					x: 0,
					y: 0,
					z: 0
				}
			},
			plane: {
				x: 0,
				y: 0,
				z: 0,
				rx: -0.5 * Math.PI,
				shadow: true
			},
			loadingNum: '0.00%',
			show: false,
			prompt: {
				page: 0,
				show: false
			}
		};
	}

	async componentDidMount(){
		// let { datas } = this.props,
		// 	show 	  = datas.show;

		// if (show) {

		// 	this.init();

		// }
		// else if (objScene) {

		// 	this.clearScene();
		// 	this.updateInfo('', {
		// 		width: 0,
		// 		height: 0
		// 	});
		// }
	}

	componentWillReceiveProps(nextProps){
		
		let lastShow = this.state.show,
			{ id, canvas, show, promptShow } = nextProps.datas;

		if (show !== lastShow) {
			if (objScene) {
				this.clearScene(function(state){

					if (state) {
						this.initDatas();
						this.updateInfo('', {
							width: 0,
							height: 0
						});
					}
				});
			}

			this.updateInfo(id, canvas, show, promptShow);
		}
	}

	updateInfo(id, canvas, show, promptShow){
		console.log(new Color('#607577'));

		id = id !== '' ? parseInt(id) : '';

		let canvasInfo = {
				width: canvas.width,
				height: canvas.height
			},
			modelInfo = {
				id: id,
				name: '',
				mtl: '',
				obj: '',
				fbx: '',
				other: {
					stroke: {
						'visible': true,
						'width': 0.0015,
						'color': "000000"
					},
					tone: {
						num: 3,
						url: './resource/assets/gradientMaps/threeTone.jpg'
					}
				}
			},
			prompt = {
				page: 0,
				show: promptShow
			}

		this.setState({
			canvasInfo,
			modelInfo,
			show,
			prompt
		}, () => {
			if (id !== '') {
				this.init();
			}
		});
	}

	init(){
		let id 	  = this.state.modelInfo.id,
			_this = this;
		
		this.setCamera();
		this.setScene();
		// this.setAmbientLight();
		// this.setSpotLight();

		this.setData(id, function(state, type, shader, ){
			state ? function(){

				_this.setLightSwitch();
				_this.setModuleSwitch(type, shader);

			}() : _this._textLog( 'error', '>> Data info error, please check 3D info and file... <<' );
		});
	}

	setData(id, callback){
		let _this 	   = this,
			// state 	   = false,
			modelInfo  = this.state.modelInfo,
			// modelDatas = this.state.modelDatas,
			type 	   = '',
			shader 	   = '';

		for (var i = 0; i < DataJson.length; i++) {
			if (DataJson[i].id === id) {
				// state 	  = true;
				modelInfo = DataJson[i];
				type 	  = modelInfo.type;
				shader	  = modelInfo.shadertype;

				break;
			}
		}

		this.getModelDatas(modelInfo.material, (state, modelDatas) => {
			state ? _this.setState({
				modelInfo,
				modelDatas
			}, () => {
				callback(state, type, shader);
			}) : _this._textLog( 'error', 'get modelDatas error...' );
		});
	}

	async getModelDatas(url, callback){
		let _this = this;

		await fetch(url)
				.then(function(res){

					return res.json();

				}).then(function(datas){

					callback(true, datas);

				}).catch(function(err){

					_this._textLog( 'error', err );

					callback(false, null);

				});
	}

	fnStringToHex(str){
		if(str === "")
		return "";

		let hexValue = "0x" + parseInt("FFFFFF", 16).toString(16).toUpperCase();

		return hexValue;
	}

	setLightSwitch(){
		let _this = this,
			arry  = this.state.modelDatas.light;

		let datas = {
						type: '',
						name: '',
						color: '',
						intensity: 0,
						castShadow: false,
						position: {
							x: 0,
							y: 0,
							z: 0
						},
					};

		for (var i = 0; i < arry.length; i++) {

			datas.type  	= arry[i].type;
			datas.name  	= arry[i].name;
			datas.color 	= _this.fnStringToHex(arry[i].color);
			datas.intensity = arry[i].intensity;

			switch(datas.type){
				case 'AmbientLight':

					_this.setAmbientLight(datas);

					break;

				case 'PointLight':
					_this.setPointLight(datas);

					break;

				case 'DirectionalLight':

					datas.castShadow = arry[i].castShadow;
					datas.position 	 = {
						x: arry[i].position[0].x,
						y: arry[i].position[0].y,
						z: arry[i].position[0].z
					};

					_this.setSpotLight(datas);

					break;

				default:
					console.log('default');
			}

		}
	}

	setModuleSwitch(type, shader){
		let _this = this;

		switch(type){
			case 'obj':

				_this.setMTLLoader( (status) => {
					_this.setPublicScene(status);
				});

				break;

			case 'fbx':
				_this.setFBXLoader( (status) => {
					_this.setPublicScene(status);
				});

				break;

			case 'gltf':
				_this.setGLTFLoader( (status, scene, result) => {
					_this.setMixer(result);
					_this.setMaterialSwitch(shader, scene);
					_this.setRenderer();
				});

				break;

			default:
				console.log('default');
		}
	}

	//纹理与着色切换
	setMaterialSwitch(shadertype, scene){
		let _this = this;

		switch(shadertype){
			case 'normal':

				objMaterial = new MeshLambertMaterial({color: 0xcccccc, transparent: true, opacity: 0.5});

				// objMaterial = new MeshStandardMaterial({color: 0xcccccc, transparent: true, opacity: 0.5});

				_this.setPlane(objMaterial);

				objScene.add(scene);

				break;

			case 'celluloid':


				_this.setTone((state, Tone) => {

					state ? function(){

						scene.traverse((child) => {

							if ( ! child.isMesh ) return;

							let prevMaterial = child.material;

							child.material = new MeshToonMaterial();

							child.material.gradientMap = Tone;

							MeshBasicMaterial.prototype.copy.call( child.material, prevMaterial );

						});

						objScene.add(scene);

					}() : _this._textLog( 'error', '?? set Tone error, please check func setTone()... ??' );

				});

				break;

			default:
				console.log('default');
		}
	}

	//设置与储存动画
	setMixer(result){
		objMixer = new AnimationMixer(result.scene);

		//用arryActions储存所有动画
		for (var i = 0; i < result.animations.length; i++) {
			arryActions[i] = objMixer.clipAction(result.animations[i]);
		}

		console.log(arryActions);

		this.setEnableControls(true);
	}

	setTone(callBack){
		let material = this.state.modelDatas,
			toneUrl  = material.textureDatas.toneUrl;

			toneUrl != null ? function (){
				objTone = new TextureLoader().load(toneUrl);

				objTone.minFilter = NearestFilter;
				objTone.magFilter = NearestFilter;
			}(): objTone = null;

		callBack(true, objTone);
	}

	setOutLine(scene, camera){
		let material = this.state.modelDatas,
			status 	 = material.stroke.visible,
			width    = material.stroke.width,
			color 	 = material.stroke.color;

		if (width > 0 && status) {
			objOutLine = new OutlineEffect( objRenderer, {
				defaultThickness: width, //线条粗细
				defaultColor: color, //线条颜色
				defaultKeepAlive: false //是否将outline材料保存在缓存中，即使outline的对象从场景中移除
			} );

			objOutLine.enabled = true;

		let canvasInfo = this.state.canvasInfo,
			w  	   = canvasInfo.width,
			h 	   = canvasInfo.height;

			objOutLine.setSize( w, h );
			objOutLine.render(scene, camera);
		}
	}

	setPublicScene(status){
		let _this = this;

		if (status) {
			_this.setControls();
			_this.setAxisHelper();
			_this.setRender(objScene, objCamera);

			_this._textLog('success', '>> Model set success! <<');
		}else{

		}
	}

	setCamera(){
		this.setScene();
		let position = this.state.camera.position,
			lookAt	 = this.state.camera.lookAt;

		let canvasInfo = this.state.canvasInfo,
			width  	   = canvasInfo.width,
			height 	   = canvasInfo.height;

		objCamera = new PerspectiveCamera(45, width / height, 1, 3000);

		objCamera.position.set(position.x, position.y, position.z);
		objCamera.updateProjectionMatrix();
		objCamera.lookAt(lookAt);
	}

	setScene(){
		objScene = new Scene();
	}

	setAmbientLight(datas){
		objAmbientLight = new AmbientLight(datas.color, datas.intensity);

		objScene.add( objAmbientLight );
	}

	setPointLight(datas){
		objPointLight = new PointLight(datas.color, datas.intensity, 100);

		objScene.add( objPointLight );
	}

	setSpotLight(datas){
		objSpotLight 		 	= new DirectionalLight(datas.color);

		objSpotLight.name 		= datas.name;
		objSpotLight.castShadow = datas.castShadow;
		objSpotLight.intensity 	= datas.intensity;

		objSpotLight.position.set(datas.position.x, datas.position.y, datas.position.z);
		objScene.add( objSpotLight );
	}

	setRenderer(){
		let BgColor = parseInt('0x' + this.state.modelDatas.colorDatas.backgroundColor);
		objRenderer = new WebGLRenderer({antialias: true});

		// 设置渲染器渲染阴影效果
		objRenderer.shadowMap.enabled 		= true;
		objRenderer.physicallyCorrectLights = true;
		objRenderer.outputEncoding 			= sRGBEncoding;
		objRenderer.autoClear 				= true;
		// objRenderer.gammaInput 				= true;
		objRenderer.gammaOutput 			= false;

		objRenderer.setClearColor( BgColor );
		objRenderer.setPixelRatio( window.devicePixelRatio );
		// objRenderer.setSize(window.innerWidth, window.innerHeight);

		// document.body.appendChild( objRenderer.domElement );

		let objBox 	   = this.refs.ThreeDmodel_window,
			canvasInfo = this.state.canvasInfo,
			width  	   = canvasInfo.width,
			height 	   = canvasInfo.height;

		objRenderer.setSize(width, height);
		objBox.appendChild( objRenderer.domElement );

		this.setPublicScene(true);
	}

	setPlane(material){

		objPlane = new Mesh(objPlaneGeometry, material);

		objPlane.rotation.x    = this.state.plane.rx;
		objPlane.position.x    = this.state.plane.x;
		objPlane.position.y    = this.state.plane.y;
		objPlane.position.z    = this.state.plane.z;
		objPlane.receiveShadow = this.state.plane.shadow; //设置投影

		objScene.add(objPlane);
	}

	//MTL
	async setMTLLoader(callBack){
		let urlMTL = this.state.modelInfo.mtl,
			_this  = this;

		await objMTLLoader.load(urlMTL, function (materials) {

			materials.preload();

			_this.setOBJLoader(materials, callBack);
			
		}, undefined, function (error) {

			console.error(error);

			callBack(false);
		});
	}

	async setOBJLoader(materials, callBack){
		let urlOBJ = this.state.modelInfo.obj;

		await objOBJLoader.setMaterials(materials).load(urlOBJ, function (object) {

			object.traverse((child) => {
				child.castShadow 	= true;
				child.receiveShadow = true;
			});

			objScene.add(object);

			callBack(true);
		});
	}


	//FBX
	async setFBXLoader(callBack){
		let urlFBX = this.state.modelInfo.fbx;

		await objFBXLoader.load(urlFBX, function (materials) {

			objScene.add(materials);
			// 适当平移fbx模型位置
			materials.translateY(-80);

			callBack(true);

		}, undefined, function (error) {

			console.error(error);

			callBack(false);
		});
	}

	//GLTF
	async setGLTFLoader(callBack){
		let _this   = this,
			urlGLTF = this.state.modelInfo.gltf;

		await objGLTFLoader.load(urlGLTF, function (gltf) {

			try{
				let scene = gltf.scene || gltf.scenes[0];
					// clips = gltf.animations || [];

				if (!scene) {
					// Valid, but not supported by this viewer.
					throw new Error(
						'This model contains no scene, and cannot be viewed here. However,'
						+ ' it may contain individual 3D resources.'
					);
				}

				scene.name 		 = 'Model';
				scene.rotation.y = Math.PI;
				scene.castShadow = true;
				scene.receiveShadow = true;
				scene.scale.set(1,1,1);
				scene.rotation.set(0,0,0);
				scene.position.set(0,0,0);

				callBack(true, scene, gltf);
			}
			catch(err){
				_this._textLog( 'error', err );
			}

		}, function (xhr) {

			try{
				let pre = parseInt(xhr.loaded / xhr.total * 100);

				if (pre >= 100 && pre !== 'Infinity') {
					_this.refs.loading_line.setAttribute('class', 'loading_line loading_line_hide');
				}else{
					_this.refs.loading_line.setAttribute('class', 'loading_line');
				}

				_this.setState({
					loadingNum: pre + '%'
				});
			}catch(err){
				_this._textLog( 'error', err );
			}

		}, function (error) {

			console.error(error);

			callBack(false);
		});
	}

	setControls(){
		objControls = new OrbitControls(objCamera, objRenderer.domElement);

		// 使动画循环使用时阻尼或自转 意思是否有惯性
		objControls.enableDamping = true;

		//动态阻尼系数 就是鼠标拖拽旋转灵敏度
		objControls.dampingFactor = 0.25;

		//是否可以缩放
		objControls.enableZoom = true;

		//是否自动旋转
		objControls.autoRotate = false;

		//设置相机距离原点的最远距离
		objControls.minDistance = 0;

		//设置相机距离原点的最远距离
		objControls.maxDistance = 10000;

		//是否开启右键拖拽
		objControls.enablePan = true;

		objControls.saveState();
	}

	setEnableControls(status){
		for (let i = 0; i < arryActions.length; i++) {
			if (status) {
				arryActions[i].play();
			}
			else {
				arryActions[i].stop();
			}
		}
	}

	//添加辅助坐标轴
	setAxisHelper(){
		let axesHelper = this.state.axesHelper;

		if (axesHelper) {
			objScene.add(objAxisHelper);
		}
	}

	//渲染
	setRender(scene, camera){
		objRenderer.toneMappingExposure = 1.0;
		objRenderer.render(scene, camera);

		this.setOutLine(scene, camera);

		animationId = window.requestAnimationFrame(function(e){
			this.setRender(objScene, objCamera);

			let delta = objClock.getDelta();

			objMixer.update(delta);
		}.bind(this));
	}

	//清除场景
	 clearScene(callback) {
	 	try{
		 	this.setEnableControls(false);
		 	this.clearThree(objScene);

			window.cancelAnimationFrame(animationId);

			objRenderer.forceContextLoss();
			objRenderer.dispose();

			objScene = null;
			objCamera = null;
			objControls = null;
			objRenderer.domElement.remove()
			objRenderer.domElement = null;
			objRenderer = null;

			callback(true);

			this._textLog('success', 'clearScene');
	 	}
	 	catch(err){
	 		callback(false);

	 		this._textLog( 'error', err );
	 	}
	}

	clearThree(obj){
	 	try{

		 	while(obj.children.length > 0){
				this.clearThree(obj.children[0]);
				obj.remove(obj.children[0]);
			}
			if(obj.geometry) obj.geometry.dispose()

			if(obj.material){ 
				//in case of map, bumpMap, normalMap, envMap ...
				Object.keys(obj.material).forEach(prop => {
					if(!obj.material[prop])
					return         
					if(typeof obj.material[prop].dispose === 'function')                                  
					obj.material[prop].dispose()                                                        
				})
				obj.material.dispose()
			}

	 	}
	 	catch(err){
	 		this._textLog( 'error', err );
	 	}
	}

	fnPromptPage(){
		let prompt 	   = this.state.prompt,
			show   	   = prompt.show,
			loadingNum = parseInt(this.state.loadingNum),
			html   	   = null;

		if (show && loadingNum === 100) {
			let page = prompt.page;

			html = this.getHtmlPromptPage(page);
		}
		else{
			html = null;
		}

		return html;
	}

	getHtmlPromptPage(page){
		let datas = [
						{page: 0, url: './resource/images/prompt/@2x/pic_prompt_1.png', text: '<p>按住鼠标左键拖动<br/>可旋转3D模型</p>', btnText: '下一步'},
						{page: 1, url: './resource/images/prompt/@2x/pic_prompt_2.png', text: '<p>按住鼠标右键拖动<br/>可平移3D模型</p>', btnText: '下一步'},
						{page: 2, url: './resource/images/prompt/@2x/pic_prompt_3.png', text: '<p>鼠标中键滚动<br/>可缩放3D模型</p>', btnText: '知道了'}
					];

		if (page <= 2) {
			return (
				<div className="promptPage">
					<div 
						className="prompt_pic" 
						style={{ backgroundImage: `url(` + datas[page].url + `)` }} 
					></div>
					<div 
						className="prompt_text" 
						dangerouslySetInnerHTML={{
								__html: datas[page].text
						}}
					></div>
					<div 
						className="animate_hover normal_animate btn_prompt_done" 
						onClick={this.fnPromptPageNext.bind(this)}
					>{ datas[page].btnText }</div>
				</div>
			);			
		}
		else{
			return null;
		}
	}

	fnPromptPageNext(){
		let page   = this.state.prompt.page,
			prompt = {
						page: 0,
						show: true
					};

		page   = page  <= 2 ? page + 1 : 0;
		prompt = {
					page: page,
					show: page <= 2
				};

		this.setState({
			prompt
		});
	}

	initDatas(){
		let loadingNum = '0.00%';

		this.setState({
			loadingNum
		});
	}





	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			this.props.datas.show ? <div ref="ThreeDmodel_window" className="ThreeDmodel_box">

										{this.fnPromptPage()}

										<div ref="loading_line" className="loading_line">
											<div className="loading_text">载入中...</div>
											<div className="icon_point"></div>
											<div className="loading_progress">
												<div className="normal_animate progress_now" style={{ width: this.state.loadingNum }}></div>
												<div className="progress_num">{this.state.loadingNum}</div>
											</div>
										</div>
									</div> : null
		);
	}
}

export default ThreeDmodel;