import React, {Component} from 'react';

import './Laboratory.css';



class Laboratory extends Component{
	constructor(...args){
		super(...args);

		this.state = {};
	}

	componentDidMount(){
		
	}

	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			<div ref="Laboratory_window" className="Laboratory_window">

				<div className="pic_default"></div>

			</div>
		);
	}
}

export default Laboratory;