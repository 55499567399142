import React, {Component} from 'react';

import './Portfolioinfo.css';



class Portfolioinfo extends Component{
	constructor(...args){
		super(...args);

		this.state = {
			datas: {}
		};
	}

	componentDidMount(){
		
	}

	componentWillReceiveProps(nextProps){
		let datas = nextProps.datas.datas,
			show  = nextProps.datas.show;

		if (show && datas !== this.state.datas && Object.keys(datas).length > 0) {
			this.updateInfo(datas);
		}
	}

	updateInfo(datas){
		this.setState({
			datas
		});
	}

	getHtml(){
		let datas = this.state.datas;

		let html_content   = datas.info ? this.getHtmlContent(datas.info) : null,
			html_label 	   = datas.label ? this.getHtmlLabel(datas.label) : null,
			html_tool 	   = datas.tool ? this.getHtmlTool(datas.tool) : null,
			html_color     = datas.color ? this.getHtmlColor(datas.color) : null,
			html_shadow    = datas.color ? this.getHtmlColorShadow(datas.color) : null,
			html_reference = datas.reference && datas.reference.length > 0 ? this.getHtmlReference(datas.reference) : null;
		


		return (
			<div>
				<h3>{ datas.title }</h3>
				<span className="date">{ datas.date }</span>
				
				{ html_content }

				{ html_label }

				{ html_tool }

				<div className="color">
					<h4>主色调</h4>
					<ul>
						{ html_color }
					</ul>
					<ul className="colorShadow">
						{ html_shadow }
					</ul>
				</div>

				{ html_reference }

			</div>

		);
	}

	getHtmlContent(info){
		return (
					<div 
						className="content" 
						dangerouslySetInnerHTML={{
							__html: info
						}}
					></div>
				);
	}

	getHtmlLabel(label){
		let html = label.map( (datas, key) => {

				return <li className="display_inlineBlock" key={ key } >
							<p className="normal_animate">{ datas }</p>
						</li>

			} );

		return (
				<ul className="label">
					{ html }
				</ul>
				);
	}

	getHtmlTool(tool){
		let html = tool.map( (datas, key) => {

				let toolClass = 'display_inlineBlock icon_tool icon_tool_' + datas.name;

				return <li className={ toolClass } 
							key={ key } 
						></li>

			} );

		return (
				<ul className="tool">
					<h4>创作工具</h4>
					{ html }
				</ul>
				);
	}

	getHtmlColor(color){
		let html = color.map( (datas, key) => {

				return <li className="display_inlineBlock" 
							key={ key } 
							style={{backgroundColor: datas.value}}
						>
							<span className="normal_animate">{ datas.value }</span>
						</li>

			} );

		return html;
	}

	getHtmlColorShadow(color){
		let html = color.map( (datas, key) => {

				return <li className="display_inlineBlock" 
							key={ key } 
							style={{backgroundColor: datas.value, boxShadow: `0px 2px 12px ` + datas.value}}
						></li>

			} );

		return html;
	}

	getHtmlReference(reference){
		let html = reference.map( (datas, key) => {

				return <li key={ key } >
							<img className="pic" src={ datas.src } alt="" />
						</li>

			} );

		return (
				<ul className="reference">
					<h4>模型参考</h4>
					{ html }
				</ul>
				);
	}

	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			<div ref="Portfolioinfo_window" className="delay_animate_600 Portfolioinfo_window">

				{ Object.keys(this.state.datas).length > 0 ? this.getHtml() : null }

			</div>
		);
	}
}

export default Portfolioinfo;