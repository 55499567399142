import React, {Component} from 'react';
import Parallax from 'parallax-js';
import lottie from 'lottie-web';

// import NavPageList from './NavPageList';
import Portfolio from './Portfolio';
import Laboratory from './Laboratory';

import '../resource/base.css';
import './Main.css';

const itemDatas = [
	{id: '1', level: '99', depth: '0.6', title: '作品集', type: 'page', page: 'Portfolio',  pic: './resource/images/tab_bg/bg_portfolio@2x.png',  width: '240px', height: '320px', top: '167', left: '243',  data: '1'},
	{id: '2', level: '99', depth: '0.6', title: '实验室', type: 'page', page: 'Laboratory', pic: './resource/images/tab_bg/bg_laboratory@2x.png', width: '240px', height: '320px', top: '278', left: '714',  data: '2'},
	{id: '3', level: '3',  depth: '0.1',  title: '', 	 type: 'pic',  page: '', 		   pic: './resource/images/tab_bg/bg_1@2x.png', 		 width: '200px', height: '120px', top: '12',  left: '60', 	data: ''},
	{id: '4', level: '3',  depth: '0.1',  title: '', 	 type: 'pic',  page: '', 		   pic: './resource/images/tab_bg/bg_2@2x.png', 		 width: '200px', height: '120px', top: '127', left: '954',  data: ''},
	{id: '5', level: '2',  depth: '0.4',  title: '', 	 type: 'pic',  page: '', 		   pic: './resource/images/tab_bg/bg_3@2x.png', 		 width: '160px', height: '226px', top: '500', left: '1009', data: ''},
	{id: '6', level: '1',  depth: '0.3',  title: '', 	 type: 'pic',  page: '', 		   pic: './resource/images/tab_bg/bg_4@2x.png', 		 width: '180px', height: '220px', top: '77',  left: '580',  data: ''},
	{id: '7', level: '1',  depth: '0.3',  title: '', 	 type: 'pic',  page: '', 		   pic: './resource/images/tab_bg/bg_5@2x.png', 		 width: '160px', height: '130px', top: '599', left: '120',  data: ''}
];

let _obj_Parallax = null;
let _MainTimer = null;



class Main extends Component{
	constructor(...args){
		super(...args);

		this.state = {
			scroll: false,
			scrollName: 'scrollMain', //scrollMain || scrollPortfolio || scrollLaboratory
			parallaxHeight: 0,
			nav: {
				cur: 0,
				list: [
					{id: 0, title: '首页', en: 'Main', page: 'Main', active: true},
					{id: 1, title: '作品集', en: 'Portfolio', page: 'Portfolio', active: false},
					{id: 2, title: '实验室', en: 'Laboratory', page: 'Laboratory', active: false}
				]
			}
		};
	}

	async componentDidMount(){
		this.setWindowSize();
		this.init();
	}

	init(){
		let _this = this;

		this.loadingScreen(function(state){
			if (state) {
				_this.fnParallaxList('init');

				_MainTimer = setTimeout(function(){
					clearTimeout(_MainTimer);

					let obj_box = _this.refs.Main_loadingScreen;

					obj_box.className += ' Main_loadingNone';

					_this.listenWindowSCroll('Main_box', true);
					_this.fnParallaxHeight();
					_this.fnParallaxList('enable');
				}, 1200);
			}
		});
	}

	setWindowSize(){
		let h 	= window.innerHeight,
			obj = document.getElementById('root');

		obj.style.minHeight = h + 'px';
	}

	getHtmlNav(){
		let navData = this.state.nav.list;

		let html = navData.map( (datas, key) => {

			let active  = datas.active ? ' nav_cur' : '',
				curClass = 'normal_animate animate_hover' + active;

			return <li 
					key={ key } 
					id={ datas.id } 
					className={ curClass }
					onClick={this.fnNavClick.bind(this, datas.id)}>
						<p className="normal_animate font-serif">{ datas.title }</p>
						<em className="normal_animate">{ datas.en }</em>
					</li>

		} );

		return (
			<ul className="display_inlineFlex">{ html }</ul>
		);
	}

	getHtmlList(){

		let html = itemDatas.map( (datas, key) => {

			let type 	  = datas.type,
				x 		  = datas.left,
				left 	  = x + 'px',
				y 		  = datas.top,
				top 	  = y + 'px',
				pageClass = '';

			switch(type){
				case 'page':

					pageClass = 'animate_hover page_' + datas.page;

					return <li 
							key={ key } 
							lid={ datas.id } 
							className={ pageClass }
							data-depth={ datas.depth }
							data-calibrate-x={ datas.left }
							data-calibrate-y={ datas.top }
							style={{width: datas.width, height: datas.height, top: top, left: left, backgroundImage: `url(` + datas.pic + `)`, zIndex: datas.level}} 
							onClick={this.fnNavClick.bind(this, datas.data)}>
								<p className="normal_animate font-serif">{ datas.title }</p>
								<div className="delay_animate_180 icon_enter"></div>
								<div className="normal_animate mask"></div>
								<em className="normal_animate">{ datas.page }</em>
							</li>

				case 'pic':
					return <li 
							key={ key } 
							lid={ datas.id } 
							className=""
							data-depth={ datas.depth }
							data-calibrate-x={ datas.left }
							data-calibrate-y={ datas.top }
							style={{width: datas.width, height: datas.height, top: top, left: left, backgroundImage: `url(` + datas.pic + `)`, zIndex: datas.level}}>	
							</li>

				default:
					return null;
			}


		} );

		return (
			<ul className="display_inlineFlex" id="Main_Item">{ html }</ul>
		);
	}

	fnNavClick(id){
		let _this = this,
			nav   = this.state.nav,
			cur   = nav.cur,
			list  = nav.list;

			id    = parseInt(id);

		for (let i = 0; i < list.length; i++) {
			if (list[i].id === id) {

				cur 		   = id;
				list[i].active = true;

			}
			else{
				list[i].active = false;
			}
		}

		nav.list = list;
		nav.cur  = cur;

		this.setState({
			nav
		}, () => {
			_this.fnScrollInit();
			_this.fnSwitchPage(id);
		});
	}

	fnSwitchPage(id){
		let obj  	  	= null,
			name 	  	= '',
			scrollName	= '',
			switchClass = '',
			arry 	  	= this.state.nav.list;

			id   	  	= parseInt(id);

		for (let i = 0; i < arry.length; i++) {

			name = 'page_' + arry[i].page;
			obj  = document.getElementById(name);

			if (arry[i].id === id) {
				switchClass = ' Switch_page_fadeIn';
				scrollName  = 'scroll' + arry[i].page;

				obj.classList.remove('Switch_page_fadeOut');
			}
			else{
				switchClass = ' Switch_page_fadeOut';

				obj.classList.remove('Switch_page_fadeIn');
			}

			obj.className += switchClass;
		}

		if (id > 0) {
			this.fnParallaxList('disable', function(datas){
				console.log(datas.info);
			});
		}
		else{
			this.fnParallaxList('enable', function(datas){
				console.log(datas.info);
			});
		}

		this.setState({
			scrollName
		});
	}

	fnScrollInit(){
		let obj = document.getElementById('Main_box');

		obj.scrollTop = 0;
	}

	listenWindowSCroll(name, state){
		let obj = name === 'window' ? window : document.getElementById(name);

		if (state) {
			obj.addEventListener('scroll', this.fnHandleScroll.bind(this, obj), false);
		}
		else if (!state) {
			obj.removeEventListener('scroll', this.fnHandleScroll.bind(this, obj), false);
		}
		else{
			return null;
		}
	}

	fnHandleScroll(obj){
		// let active = this.state.nav.list[0].active;

		// if (active) {
			let scroll 	  = false,
				scrollTop = obj === window ? document.documentElement.scrollTop : obj.scrollTop;  //滚动条滚动高度

			if(scrollTop > 30){
				scroll = true;
			}
			else{
				scroll = false;
			}

			this.setState({
				scroll
			});
		// }
	}

	fnParallaxList(type, callback){
		let state = '',
			info  = '';

		callback = typeof callback === 'function' ? callback : () => {};

		try{
			switch(type){
				case 'init':

					let obj   = document.getElementById('Main_Item');

					_obj_Parallax = new Parallax(obj, {
						calibrateX: true,
						calibrateY: true,
						invertX: false,
						invertY: false,
						relativeInput: true,
						clipRelativeInput: false,
						hoverOnly: false
					});

					_obj_Parallax.disable();

					this.fnResetInitPosition();

					state = 'success';
					info  = 'Parallax init success';

					callback({state, info});

					return {state, info};

				case 'enable':

					_obj_Parallax.enable();

					state = 'success';
					info  = 'Parallax enable success';

					callback({state, info});

					return {state, info};

				case 'disable':

					_obj_Parallax.disable();

					state = 'success';
					info  = 'Parallax disable success';

					callback({state, info});

					return {state, info};

				default:
					return null;
			}

		}catch(error){

			console.error(error);

		}

	}

	fnParallaxHeight(){
		let scrollHeight = document.getElementById('Main_box').scrollHeight,
			parallaxHeight = scrollHeight - 110 - 72; //PageList = Main_box - headNav - Main_logo

		this.setState({
			parallaxHeight
		});
	}

	fnResetInitPosition(){
		let obj  = document.getElementById('Main_Item').childNodes,
			top  = '0',
			left = '0';

		for (let i = 0; i < obj.length; i++) {
			top  = obj[i].getAttribute('data-calibrate-y') + 'px';
			left = obj[i].getAttribute('data-calibrate-x') + 'px';

			obj[i].style.top = top;
			obj[i].style.left = left;
		}
	}

	loadingScreen(callback){
		let obj_box = this.refs.Main_loadingScreen,
			obj 	= this.refs.Main_loadingScreen_pic,
			src 	= './resource/images/mainLoadingScreen.json',
			state 	= {
							animate: false,
							dom: false,
						};

		lottie.loadAnimation({
			container: obj,
			renderer: 'svg',
			loop: false,
			path: src,
		}).addEventListener('complete', () => {
			state.animate = true;
		});

		if (document.onreadystatechange !== undefined && document.onreadystatechange !== null) {
			document.onreadystatechange = function() {
				if (document.readyState === 'complete') {
					state.dom = true;
				}
			};
		} else {
			document.addEventListener("DOMContentLoaded", function() {
				console.log('DOMContentLoaded!');
			});
		}

		_MainTimer = setInterval(function(){

			if (document.readyState === 'complete') {
				state.dom = true;
			}

			if (state.animate && state.dom) {

				clearInterval(_MainTimer);

				obj_box.className += ' Main_loadingDone';

				callback(true);
			}
		}, 1000);
	}

	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			<div ref="Main_window" className="set_widowSize Main_window">

				<div ref="Main_loadingScreen" className="delay_animate_600 Main_loadingScreen">
					<div ref="Main_loadingScreen_pic" className="Main_loadingScreen_pic"></div>
					<div className="normal_animate font-serif loading_text"><i>Loading...</i></div>
					<div className="normal_animate loading_done"></div>
				</div>

				<div className={`Main_box ${this.state.scroll ? this.state.scrollName : ''}`} id="Main_box">
				
					<div className="normal_animate Main_headNav" id="Main_headNav">

						{ this.getHtmlNav() }

					</div>

					<div className="fast_animate Main_logo">
						<div className="logo_pic"></div>
						<div className="logo_text"></div>
					</div>

					<div className="Window_PageList" style={{height: this.state.parallaxHeight + `px`}}>

						<div className="normal_animate Window_PageItem Switch_page_fadeIn Main_item" id="page_Main" page="page_Main">

							{/*<NavPageList />*/}

							{ this.getHtmlList() }

						</div>

						<div className="normal_animate Window_PageItem Switch_page_fadeOut Portfolio_item" id="page_Portfolio" page="page_Portfolio">
							<Portfolio
									show={this.state.nav.cur === 1 ? true : false}
									datas="0"
								 />
						</div>

						<div className="normal_animate Window_PageItem Switch_page_fadeOut Laboratory_item" id="page_Laboratory" page="page_Laboratory">
							<Laboratory
									show={this.state.nav.cur === 2}
								 />
						</div>
						
					</div>

					<div className="Main_box_bg"></div>

				</div>

			</div>
		);
	}
}

export default Main;