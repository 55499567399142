import React, {Component} from 'react';

import './Articles.css';



class Articles extends Component{
	constructor(...args){
		super(...args);

		this.state = {
			datas: {}
		};
	}

	componentDidMount(){
		
	}

	componentWillReceiveProps(nextProps){
		
		// console.log(nextProps);

		let datas = nextProps.datas,
			show  = nextProps.datas.show;

		if (show && datas !== this.state.datas && Object.keys(datas).length > 0) {
			this.updateInfo(datas);
		}
	}

	// static getDerivedStateFromProps(props, state){
	// 	console.log(props, state);
	// }

	// componentDidUpdate(prevProps){
	// 	console.log(prevProps);
	// }

	updateInfo(datas){
		this.setState({
			datas
		});
	}

	getHtml(){
		let datas 	= this.state.datas,
			banner  = datas.content.banner,
			content = datas.content.messages;

		let html_banner  = this.getHtmlBanner(banner),
			html_content = this.getHtmlContent(content);

		return (
			<div>
				{ html_banner }
				{ html_content }
			</div>
		);
	}

	getHtmlBanner(datas){

		return(
			<img className="img_unselecTable Articles_banner" src={ datas } alt="" draggable="false" />
		);
	}

	getHtmlContent(datas){
		let _this = this;
		let html  = datas.map( (datas, key) => {

				return _this.getTypeContent(datas, key);

			} );

		return (
			<div className="Articles_content">
				{ html }
			</div>
		);
	}

	getTypeContent(datas, key){
		let type = datas.type,
			html = '';

		switch(type){
			case 'pic':
				html = <img key={ key } className="pic" src={ datas.src } alt="" />
				break;

			case 'html':
				html = <div key={ key } className="html" dangerouslySetInnerHTML={{ __html: datas.html }}></div>
				break;

			default:
				console.log('default');
		}

		return html;
	}

	_textLog(type, info){
		switch(type){
			case 'normal':
				console.log(info);
				break;

			case 'success':
				console.log(`%c ${info}` , 'color: #ffffff; background: #43bb88');
				break;

			case 'warn':
				console.log(`%c ${info}` , 'color: #be7931; background: #fff35c');
				break;

			case 'error':
				console.log(`%c ${info}` , 'color: #ffffff; background: #e65454');
				break;

			default:
				console.log('default');
		}
	}



	render(){
		return(
			this.props.datas.show ? <div ref="Articles_window" className="Articles_window">

										{ Object.keys(this.state.datas).length > 0 ? this.getHtml() : null }

									</div> : null
		);
	}
}

export default Articles;