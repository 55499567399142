/**
 * @auhor 		by xiamu
 * @Date 		2024/04/02
 * @version 	v1.0.0
 * @content 
 * 				window size listener
 * 				
 * @param 		Function 			init()								function initialization
 * @param 		Function 			changePage()						update data and perform switch animations
 * @return 		Function 			callback({width, height, type})	{
 *                                        								type: 'init' | 'upDate' | 'remove'
 * 																	    width: num
 * 																	    height: num
 * 																     }
 */



const WindowSizeListener = function (CallBack){
	let _this 	  = this;
	let _CallBack = CallBack;

	let _datas 	  = {
						width: 0, 
						height: 0,
						type: ''
					};

	let _setTime  = null,
		_time 	  = 0;

	this.init = function(){
		this.remove();
		this.add();

		_datas.type = 'init';
	}

	this.timeOut = function(){
		window.clearTimeout(_setTime);

		if (_time === 0) {
			_setTime = window.setTimeout(function(){
				_time ++;

				_this.upDate();
				window.clearTimeout(_setTime);
				_time = 0;
			}, 1000);
		}
	}

	this.upDate = function(){
		_datas.type   = 'upDate';
		_datas.width  = window.innerWidth;
		_datas.height = window.innerHeight;

		if (_CallBack !== null) _CallBack(_datas);
	}

	this.add = function(){
		window.addEventListener('resize', (e) => {
			_this.timeOut();
		});
	}

	this.remove = function(){
		window.removeEventListener('resize', (e) => {
			// console.log(e);
		});
	}

};

export default WindowSizeListener;

