const DeviceCheck = function(){
	let device  = _isDevice(),
		system  = _isSystem(),
		browser = _isBrowser(),
		datas 	= {
						device: device,
						system: system,
						browser: browser
					};

	return datas;
}

const _isDevice = function(){
	let ua 			   = navigator.userAgent,
		isWindowsPhone = /(?:Windows Phone)/.test(ua),
		isSymbian 	   = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
		isAndroid 	   = /(?:Android)/.test(ua),
		isFireFox 	   = /(?:Firefox)/.test(ua),
		// isChrome  	   = /(?:Chrome|CriOS)/.test(ua),
		isTablet  	   = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
		isPhone   	   = /(?:iPhone)/.test(ua) && !isTablet,
		isPc 	  	   = !isPhone && !isAndroid && !isSymbian;

	let device = '';

	if (isAndroid || isPhone) {
		device = 'phone';
	} 
	else if (isTablet) {
		device = 'pad';
	} 
	else if (isPc) {
		device = 'pc';
	}
	else{
		device = 'unKnow';
	}

	return device;
}

const _isSystem = function(){
	let u         = navigator.userAgent,
		isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
		isiOS     = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || u.indexOf('Mac OS X') > -1 || u.indexOf('iPhone OS') > -1;

	let sys = '';

	if (isAndroid) {
		sys = 'android';
	}
	else if (isiOS) {
		sys = 'ios';
	}
	else{
		sys = 'unKnow';
	}

	return sys;
}

const _isBrowser = function(){
	let ua = navigator.userAgent.toLowerCase();

	if (/msie/i.test(ua) && !/opera/.test(ua)) {

		return 'IE';
	} 
	else if (/firefox/i.test(ua)) {

		return 'Firefox';
	} 
	else if (/chrome/i.test(ua) && /webkit/i.test(ua) && /mozilla/i.test(ua)) {

		return 'Chrome';
	} 
	else if (/opera/i.test(ua)) {

		return 'Opera';
	} 
	else if (/iPad/i) {

		return 'ipad';
	}

	if (/webkit/i.test(ua) && !(/chrome/i.test(ua) && /webkit/i.test(ua) && /mozilla/i.test(ua))) {

		return 'Safari';
	} 
	else {

		return 'unKnow';
	}
}

export default DeviceCheck;